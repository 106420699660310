// USA
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    COMFIRMATION_MSG: {
      ARE_YOU_SURE_YOU_WANT_TO_DELETE: 'Are you sure you want to delete ?',
      YES_DELETE: 'Yes, Delete!',
      NO_CANCEL: 'No, Cancel!',
    },
    TITLE: {
      EDIT_STANDARD: 'Edit Standard',
      CREATE_STANDARD: 'Create Standard',
      CREATE_COUNTRY: 'Create Country',
      CREATE_REGION: 'Create Region',
      FILTERS: 'Filters',
      RESET_PASSWORD: 'Reset Password',
      CREATE_SUBCATEGORY: 'Create Subcategory',
      EDIT_ESG_SUB_CATEGORY: 'Edit ESG Subcategory',
      EDIT_TOPIC: 'Edit Topic',
      GENERATE_REPORT: 'Generate Report',
      ASSIGN_ESG: 'Assign ESG',
      ASSIGN_UNIVERSAL_STANDARD_TOPIC: 'Assign Universal Standard Topic',
      EDIT_AUDIT_RECORD: 'Edit Audit Record',
      LOGS: 'Logs',
      COMPLETE_GOAL: 'Complete Goal',
      CREATE_GOAL: 'Create Goal',
      EDIT_EMAIL_TEMPLATE: 'Edit Email Template',
      CREATE_EMAIL_TEMPLATE: 'Create Email template',
      ONBOARDING: 'Onboarding',
      SMTP_MAIL_SETTTINGS_FORM: 'SMTP Mail Settings Form',
      CREATE_AUDIT_RECORD: 'Create Audit Record',
      CREATE_SECTOR: 'Create Sector',
      EDIT_SECTOR: 'Edit Sector',
      CREATE_SDG_GOAL: 'Create SDG Goal',
      ASSIGN_GRI: 'Assign GRI',
      ASSIGN_TOPICS: 'Assign Topics',
      ASSIGN_STANDARDS: 'Assign Standards',
      SELECT_TOPICS: 'Select Topics',
      EDIT_DEPARTMENT: 'Edit Department',
      CREATE_DEPARTMENT: 'Create Department',
      CREATE_USER: 'Create User',
      EDIT_USER: 'Edit User',
      CREATE_ROLE: 'Create Role',
      EDIT_ROLE: 'Edit Role',
      CREATE_PERMISSION: 'Create Permission',
      EDIT_PERMISSION: 'Edit Permission',
      EMAIL_DETAILS: 'Email Details',
      EDIT_GOAL: 'Edit Goal',
      CREATE_MAIN_TOPIC: 'Create Main Topic',
      CREATE_SECTION: 'Create Section',
      CREATE_DETAIL: 'Create Detail',
      UPDATE_MAIN_TOPIC: 'Update Main Topic',
      UPDATE_SECTION: 'Update Section',
      UPDATE_DETAIL: 'Update Detail',
      CREATE_MAIN_HEADING: 'Create Main Heading',
      UPDATE_HEADING: 'Update Heading',
      UPDATE_SUB_HEADING: 'Update Sub Heading',
      CREATE_SUB_HEADING: 'Create Sub Heading',
      CREATE_LABEL: 'Create Label',
      UPDATE_LABEL: 'Update Label',
      CREATE_TOPIC: 'Create Topic',
      APPROVAL_REMARKS: 'Approval Remarks',
      CREATE_LOCATION: 'Create Location',
      EDIT_LOCATION: 'Edit Location',
      CALCULATION: 'Calculation',
      CARBON_FOOTPRINT_CALCULATION: 'Carbon Footprint Calculation',
      LCA_CALCULATION: 'LCA Calculation',
      AI_MATERIALITY_ANALYSIS:'AI Materiality Analysis'
    },
    ERROR_TEXT: {
      INVALID_FIELD: 'Invalid Field',
      THIS_FIELD_IS_REQUIRED: 'This field is required',
      PLEASE_ENTER_VALID_EMAIL_ADDRESS: 'Please enter valid email address',
    },
    TAGIFY: {
      ADMIN: 'Admin',
      HR: 'HR',
      IT: 'IT',
      PRODUCTION: 'Production',
      SUPPLY_CHAIN: 'Supply Chain',
      FINANCE_DEP: 'Finance Dep',
      CUSTOMER_SERVICE: 'Customer Service',
      PERCENTAGE: 'PERCENTAGE',
      NUMERIC: 'Numeric',
      BOOLEAN: 'Boolean',
      CUSTOM: 'Custom',
      NUMBER: 'Number',
      STRING: 'String',
      DATE: 'Date',
      DAILY: 'Daily',
      WEEKLY: 'Weekly',
      MONTHLY: 'Monthly',
      QUARTERLY: 'Quarterly',
      ANNUALLY: 'Annually',
      MINIMUM: 'minimum',
      MAXIMUM: 'maximum',
      SUM: 'sum',
      AVERAGE: 'average',
    },
    PLACE_HOLDER: {
      SELECT_SCOPE: 'Select Scope',
      SELECT_GOAL_STATUS: 'Select Goal Status',
      SELECT_LOCATION: 'Select Location',
      SELECT_COUNTRY : 'Select Country',
      SELECT_REGION : 'Select Region',
      SEARCH: 'Search',
      TITLE_VALUE: 'Title Value',
      ADD_PURPOSE: 'Add purpose',
      SELECT_GOAL_TYPE: 'Select Goal Type',
      SELECT_VALUE_TYPE: 'Select Value Type',
      SELECT_A_DATE: 'Select a date',
      SELECT_DEPARTMENT: 'Select Department',
      SELECT_UNIT: 'Select Unit',
      SELECT_AUDIT_REFERENCE: 'Select Audit Reference',
      SELECT_TRACKING_FREQUENCY: 'Select Tracking Frequency',
      SELECT_CALCULATION_METHOD: 'Select Calculation Method',
      SELECT_REPORTING_STANDARD : 'Select Reporting Standard',
    },
    LABEL: {
      LOCATION  : 'Location',
      REGION : 'Region',
      COUNTRY : 'Country',
      FINAL_OBSERVATION: 'Final Observation',
      SELECT_SCOPE: 'Select Scope',
      OBSERVATION_PERIOD: 'Observation Period',
      ENTER_OBSERVATION_PERIOD: 'Enter Observation Period',
      GRACE_PERIOD: 'Grace Period',
      ENTER_GRACE_PERIOD: 'Enter Grace Period',
      CODE: 'Code',
      NEW_PASSWORD: 'New Password',
      USER_NAME: 'User Name',
      ENTER_CUSTOM_UNIT: 'Enter Custom Unit',
      CUSTOM_UNIT: 'Custom Unit',
      SECTORS: 'Sectors',
      OBJECTIVE: 'Objective',
      METHODOLOGY: 'Methodology',
      TOPIC_NAME: 'Topic Name',
      FINAL_VALUE: 'Final Value',
      REMARKS: 'Remarks',
      DISPLAY_NAME: 'Display Name',
      PASSWORD: 'Password',
      HOST: 'Host',
      PORT: 'Port',
      ACTIVE: 'Active',
      DEFAULT: 'Default',
      TEMPLATE_NAME: 'Template Name',
      SUBJECT: 'Subject',
      SEVERITY: 'Severity',
      BLOCKER: 'Blocker',
      ACTION_PLAN: 'Action Plan',
      NOTE: 'Note',
      MANAGERS_REMARKS: "Manager's Remarks",
      CURRENT_VALUE: 'Current Value',
      OBJECTIVES: 'Objectives',
      START_DATE: 'Start Date',
      END_DATE: 'End Date',
      SCOPE: 'Scope',
      PURPOSE: 'Purpose',
      TITLE: 'Title',
      DESCRIPTION: 'Description',
      TARGET: 'Target',
      INITIAL_VALUE: 'Initial Value',
      UNIT_VALUE: 'Unit Value',
      CALCULATION_METHOD: 'Calculation Method',
      TRACKING_FREQUENCY: 'Tracking Frequency',
      DEADLINE: 'Deadline',
      VALUE_TYPE: 'Value Type',
      GOAL_TYPE: 'Goal Type',
      DEPARTMENT: 'Department',
      UNIT: 'Unit',
      AUDIT_REFERENCE: 'Audit Reference',
      NAME: 'Name',
      IS_ACTIVE: 'Is Active',
      LAST_NAME: 'Last Name',
      FIRST_NAME: 'First Name',
      EMAIL: 'Email',
      PHONE: 'Phone',
      USERNAME: 'Username',
      TO_EMAIL: 'To Email',
      VERSION: 'Version',
      BODY: 'Body',
      FROM_EMAIL: 'From Email',
      SENT_BY: 'Sent by',
      SENT_TIME: 'Sent Time',
      TO: 'To',
      VIEW_DETAILS: 'View Details',
      FROM: 'From',
      MESSAGE: 'Message',
      HEADING: 'Heading',
      GUIDANCE: 'Guidance',
      DETAILINFO: 'Detail Info',
      LABEL: 'Label',
      CSS_CLASS: 'Css Class',
      IMAGE: 'Image',
      SUB_CATEGORY_IMAGE: 'Sub Category Image',
      CHOOSE_CARD_COLOR: 'Choose card color',
      CHOOSE_CARD_THEME: 'Choose card theme',
      GRI_CODE: 'GRI Code',
      BASELINE_VALUE: 'Baseline Value',
      VERSION_NAME: 'Version Name',
      ADDRESS: 'Address',
      LATITUDE: 'Latitude',
      LONGITUDE: 'Longitude',
      PARTS_OMITTED:'Parts Omitted',
      RESASON_FOR_PARTS_OMITTED:'Reason for Parts Omitted',
      REASON_FOR_OMISSION:'Reason for Omission',
      REPORTING_STANDARDS:'Reporting Standards',
      SECTOR:'Sector',
      SUB_SECTOR:'Sub Sector',
      EXTERNAL_LINK:'External Link',
      REPORTING_STANDARD_CODE:'Reporting Standard Code',
    },
    BUTTON: {
      VIEW_ANALYTICS: 'View Analytics',
      LCA_CALC: 'LCA Calc',
      CARBON_CALC: 'Carbon Calc',
      APPLY: 'Apply',
      ABANDONED: 'Abandoned',
      COMPLETED: 'Completed',
      INPROGRESS: 'Inprogress',
      CREATED: 'Created',
      ALL: 'All',
      RESET: 'Reset',
      RESET_PASSWORD: 'Reset Password',
      SUMMARIZE: ' Summarize',
      GENERATE: 'Generate',
      GENERATE_GRI: 'Generate GRI',
      LINK_TO_ESG: 'Link To ESG',
      ASSIGN_TOPIC: 'Assign Topic',
      UPDATE: 'Update',
      SEND_EMAIL: ' Send Email',
      TRACK_GOAL: 'Track Goal',
      HISTORY: 'History',
      ADD: 'ADD',
      SUBMIT: 'Submit',
      CREATE: 'Create',
      EXPORT_EXCEL: 'Export Excel',
      ASSIGN: 'Assign',
      CANCEL: 'Cancel',
      ACTION: 'Action',
      EDIT: 'Edit',
      DELETE: 'Delete',
      LINK_TO_TOPIC: 'Link To Topic',
      OK: 'Ok',
      SAVE: 'Save',
      CLOSE: 'Close',
      AI_ANALYSIS:'AI Analysis',
      CONSULT:'Consult',
    },
    ONBOARDING: {
      STOP: 'Stop',
      AI_WARNING_MESSAGE:
        'Please check the AI content carefully before sending',
      WRITE_WITH_AI: 'Write with AI',
      USER_ONBOARDING: 'User Onboarding',
      DEPARTMENT: 'Department',
      EMPLOYEE: 'Employee',
    },
    TABLE_HEADER: {
      STANDARDS: 'Standards',
      CODE: 'Code',
      REGIONS: 'Regions',
      COUNTRIES: 'Countries',
      ORGANIZATION_PROFILE: 'Organization Profile',
      HEADING: 'Heading',
      USER: 'User',
      DATE: 'Date',
      CAPTURED_VALUE: 'Captured Value',
      ACTION: 'Action',
      REMARKS: 'Remarks',
      UPDATE_HISTORY: 'Update History',
      DEPARTMENTS: 'Departments',
      PERMISSIONS: 'PERMISSIONS',
      ROLES: 'Roles',
      USERS: 'Users',
      NAME: 'Name',
      DESCRIPTION: 'Description',
      STATUS: 'Status',
      ACTIONS: 'Actions',
      USERNAME: 'Username',
      EMAIL: 'Email',
      PHONE: 'Phone',
      TEMPLATE_NAME: 'Template Name',
      SUBJECT: 'Subject',
      CREATE: 'Create',
      SECTORS: 'Sectors',
      GOAL_NAME: 'Goal Name',
      INITIAL_VALUE: 'Initial Value',
      TARGET_VALUE: 'Target Value',
      CURRENT_VALUE: 'Current Value',
      DUE_DATE: 'Due Date',
      EMAIL_TEMPLATES: 'Email Templates',
      CREATE_SUB_CATEGORY: 'Create Sub Category',
      FINAL_VALUE: 'Final Value',
      BASELINE_VALUE: 'Baseline Value',
      LOCATIONS: 'Locations',
      ADDRESS: 'Address',
      NOTIFICATION:'Notification',
    },
    SECTORS: {
      SECTORS: 'Sectors',
    },
    DASH: {
      COMPUTING_YOUR_QUERY: 'Computing your query',
      COMPLETE: 'Complete',
      SELECT: 'Select',
      DECISION: 'Decision',
      CONTRIBUTORS_REMARKS: "Contributor's Remarks",
      MANAGER_APPROVAL_FOR_GOAL_TRACKING: 'Manager Approval for Goal Tracking',
      ENTERED_VALUE: 'Entered Value',
      ACTION_PLAN: 'Action Plan',
      BLOCKER: 'Blocker',
      NOTE: 'Note',
      ESG_CATEGORIES_ABBREVATION:
        'ESG (Environmental, Social, and Governance) Categories',
      ESG_OVERVIEW: 'ESG Overview',
      ENVIRONMENTAL: 'Environmental',
      SOCIAL: 'Social',
      GOVERNANCE: 'Governance',
      CARBON_EMISSIONS: 'Carbon Emissions',
      WASTE_MANAGEMENT: 'Waste Management',
      ENERGY_EFFICIENCY: 'Energy Efficiency',
      HUMAN_RIGHTS_AND_LABOR_STANDARDS: 'Human Rights and Labor Standards',
      COMMUNITY_RELATIONS: 'Community Relations',
      HEALTH_AND_SAFETY: 'Health and Safety',
      SUPPLY_CHAIN_MANAGEMENT: 'Supply Chain Management',
      HUMAN_CAPITAL_MANAGEMENT: 'Human Capital Management',
      BIODIVERSITY_AND_CONSERVATION: 'Biodiversity and Conservation',
      SUSTAINABLE_WATER_MANAGEMENT: 'Sustainable Water Management',
      ENVIRONMENTAL_POLICY_AND_REPORT: 'Environmental Policy and Report',
      AUDIT_AND_INTERNAL_CONTROLS: 'Audit And Internal Controls',
      CORPORATE_RESILIENCE_AND_ADAPTABILITY:
        'Corporate Resilience and Adaptability',
      TRANSPARENCY_AND_REPORTING: 'Transparency and Reporting',
      STAKEHOLDER_ENGAGEMENT: 'Stakeholder Engagement',
      CORPORATE_CULTURE_AND_ETHICS: 'Corporate Culture and Ethics',
      HISTORY: 'History',
      TRACK_GOAL: 'Track Goal',
      STATUS: 'Status',
      LOGS: 'Logs',
      USER: 'User',
      CAPTURED_VALUE: 'Captured Value',
      ACTION: 'Action',
      REMARKS: 'Remarks',
      ROLE: 'Role',
      INOVATION:'Inovation'
    },
    MENU: {
      REGIONS: 'Regions',
      COUNTRIES: 'Countries',
      LOCATIONS: 'Locations',
      REPORTING_TOPICS: 'Reporting Topics',
      PROFILE_TOPIC_MAPPING: 'Profile Topic Mapping',
      USER_MANAGEMENT: 'User Management',
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      DEPARTMENT: 'Departments',
      USERS: 'Users',
      ROLES: 'Roles',
      MASTER_DATA: 'Master Data',
      SECTORS: 'Sectors',
      ESG_GOALS_AND_TARGETS: 'ESG Goals and Targets',
      ESG_CATEGORIES: 'ESG Categories',
      MY_GOAL_TRACKING: 'My Goal Tracking',
      GOAL_TRACKING: 'Goal Tracking',
      SDG_GOALS: 'SDG Goals',
      ESG_AUDIT: 'ESG Audit',
      ESG_AUDIT_TRACKING: 'ESG Audit Tracking',
      ACCOUNT_MANAGEMENT: 'Account Management',
      PERMISSIONS: 'Permissions',
      ONBOARDING: 'Onboarding',
      COMMUNICATION: 'Communication',
      EMAIL_TEMPLATE: 'Email Template',
      SMTP_SETTINGS: 'SMTP Settings',
      PROFILE: 'Profile',
      PROFILE_FORMS: 'Profile Forms',
      MEASURABLE:'Measurable',
      NON_MEASURABLE:'Non Measurable',
    },
    MASTER_DATA: {
      ADD_NEW_TRACKING: 'Add New Tracking',
      GOAL_TRACKING: 'Goal Tracking',
      VIEW_GOALS: 'View Goals',
      ADD_GOAL_FOR: 'Add Goal for',
      ESG_GOALS_AND_TARGETS: 'ESG Goals and Targets',
      ENVIRONMENTAL: 'Environmental',
      SOCIAL: 'Social',
      GOVERNANCE: 'Governance',
      GOALS: 'Goals',
      AUDIT_RECORDS: 'Audit Records',
      CREATE_GOAL: 'Create Goal',
      CREATE_AUDIT: 'Create Audit',
      NO_POVERTY: 'No Poverty',
      ZERO_HUNGER: 'Zero Hunger',
      GOOD_HEALTH_AND_WELL_BEING: 'Good Health and Well-being',
      GENDER_EQUALITY: 'Gender Equality',
      QUALITY_EDUCATION: 'Quality Education',
      AFFORDABLE_AND_CLEAN_ENERGY: 'Affordable and Clean Energy',
      CLEAN_WATER_AND_SANITATION: 'Clean Water and Sanitation',
      DECENT_WORK_AND_ECONOMIC_GROWTH: 'Decent Work and Economic Growth',
      CLIMATE_ACTION: 'Climate Action',
      LIFE_BELOW_WATER: 'Life Below Water',
      INDUSTRY_INNOVATION_AND_INFRASTRUCTURE:
        'Industry, Innovation, and Infrastructure',
      SUSTAINABLE_CITIES_AND_COMMUNITIES: 'Suatainable Cities and Communities',
      REDUCED_INEQUALITY: 'Reduced Inequlity',
      RESPONSIBLE_CONSUMPTION_AND_PRODUCTION:
        'Responsible Consumption and Production',
      LIFE_ON_LAND: 'Life on Land',
      PEACE_JUSTICE_AND_STRONG_INSTITUTIONS:
        'Peace, Justice, and Strong Institutions',
      PARTNERSHIPS_FOR_THE_GOALS: 'Partnerships for the Goals',
      YOU_HAVE_LENGTH_ESG_SUB_CATEGORIES:
        'You have {{length}} ESG subcategories',
      THE_ORGANIZATION_AND_ITS_REPORTING_PRACTICES:
        'The organization and its reporting practices',
      ACTIVITIES_AND_WORKERS: 'Activities and workers',
      STRATEGY_POLICIES_AND_PRACTICES: 'Strategy, policies and practices',
      STAKEHOLDER_ENGAGEMENT: 'Stakeholder engagement',
      MATERIAL_TOPIC: 'Material Topic',
      ORGANIZATION_DETAILS_MANAGEMENT: 'Organization Details Management',
      THE_ORGANIZATION_AND_ITS_REPORTING_PRACTICESS:
        'The organization and its reporting practicess',
      PROFILE_DETAILS: 'Profile Details',
    },
    ESG_AUDIT: {
      COMPLETE_AUDIT_TRACKING: 'Complete Audit Tracking',
      UPDATE_AUDIT_TRACKING: 'Update Audit Tracking',
      GENERAL: 'General',
      GENERATE_GRI: 'Generate GRI',
      AUDIT_RECORDS: 'Audit Records',
      SELECT_PARTICIPANTS: 'Select Participants',
      CREATE_AUDIT_TRACKING: 'Create Audit Tracking',
      PARTICIPANTS: 'Participants',
      ADD_AUDIT_RECORS_AND_TRACK_IT: 'Add Audit Records and Track it',
      CLICK_ON_BELOW_BUTTON_TO: 'Click on the below buttons to',
      ADD_AUDIT_RECORDS: 'add audit records',
    },
    AUDIT_ENGAGEMENTS: {
      SELECT_REPORT_TYPE : 'Select Report Type',
      COUNTRY: 'Country',
      REGION: 'Region',
      GENERATE_REPORT_BY: 'Generate Report By',
      LOCATION: 'Location',
      OBSERVATIONS: 'Observations',
      ADD_OBSERVATION: 'Add Observation',
      ATTACH_EVIDENCE: 'Attach Evidence',
      EVIDENCE: 'Evidence',
      EVIDENCES: 'Evidences',
      RECOMENDATIONS: 'Recommendations',
      ADD_EVIDENCE: 'Add Evidence',
      ADD_RECOMMENDATION: ' Add Recommendation',
      SELECT_GOALS: 'Select Goals',
      IS_IMPLEMENTED: 'Is Implemented',
      OMISSION :'Omission',
      
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: "Don't have an account?",
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.',
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        FIELD_IS_NOT_VALID: 'Field is not valid',
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields',
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted',
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created',
        },
      },
    },

    ESG_SUB_CATEGORY: {
      ADD_IMAGE: 'Add Image',
      SUB_CATEGORY_IMAGE: 'Sub Category Image',
    },
  },
};
