// Arabic
export const locale = {
  lang: 'ar',
  data: {
    TRANSLATOR: {
      SELECT: 'اختر لغتك',
    },
    COMFIRMATION_MSG: {
      ARE_YOU_SURE_YOU_WANT_TO_DELETE: 'هل أنت متأكد أنك تريد الحذف؟',
      YES_DELETE: 'نعم، احذف!',
      NO_CANCEL: 'لا، إلغاء!',
    },
    TITLE: {
      EDIT_STANDARD: 'تحرير المعيار',
      CREATE_STANDARD: 'إنشاء معيار',
      CREATE_COUNTRY: 'إنشاء دولة',
      CREATE_REGION: 'إنشاء منطقة',
      FILTERS: 'مرشحات',
      RESET_PASSWORD: 'إعادة تعيين كلمة المرور',
      CREATE_SUBCATEGORY: 'إنشاء فئة فرعية',
      EDIT_ESG_SUB_CATEGORY: 'تعديل الفئة الفرعية للبيئة والمجتمع والحوكمة',
      EDIT_TOPIC: 'تعديل الموضوع',
      GENERATE_REPORT: 'إنشاء تقرير',
      ASSIGN_ESG: 'تعيين البيئة والمجتمع والحوكمة',
      ASSIGN_UNIVERSAL_STANDARD_TOPIC: 'تعيين موضوع المعيار العالمي',
      EDIT_AUDIT_RECORD: 'تعديل سجل التدقيق',
      LOGS: 'السجلات',
      COMPLETE_GOAL: 'إكمال الهدف',
      CREATE_GOAL: 'إنشاء هدف',
      EDIT_EMAIL_TEMPLATE: 'تعديل قالب البريد الإلكتروني',
      CREATE_EMAIL_TEMPLATE: 'إنشاء قالب البريد الإلكتروني',
      ONBOARDING: 'التعريف',
      SMTP_MAIL_SETTTINGS_FORM: 'نموذج إعدادات بريد SMTP',
      CREATE_AUDIT_RECORD: 'إنشاء سجل تدقيق',
      CREATE_SECTOR: 'إنشاء قطاع',
      EDIT_SECTOR: 'تعديل القطاع',
      CREATE_SDG_GOAL: 'إنشاء هدف التنمية المستدامة',
      ASSIGN_GRI: 'تعيين GRI',
      ASSIGN_TOPICS: 'تعيين المواضيع',
      ASSIGN_STANDARDS: 'تعيين المعايير',
      SELECT_TOPICS: 'اختيار المواضيع',
      EDIT_DEPARTMENT: 'تعديل القسم',
      CREATE_DEPARTMENT: 'إنشاء قسم',
      CREATE_USER: 'إنشاء مستخدم',
      EDIT_USER: 'تعديل المستخدم',
      CREATE_ROLE: 'إنشاء دور',
      EDIT_ROLE: 'تعديل الدور',
      CREATE_PERMISSION: 'إنشاء إذن',
      EDIT_PERMISSION: 'تعديل الإذن',
      EMAIL_DETAILS: 'تفاصيل البريد الإلكتروني',
      EDIT_GOAL: 'تعديل الهدف',
      CREATE_MAIN_TOPIC: 'إنشاء موضوع رئيسي',
      CREATE_SECTION: 'إنشاء قسم',
      CREATE_DETAIL: 'إنشاء تفصيل',
      UPDATE_MAIN_TOPIC: 'تحديث الموضوع الرئيسي',
      UPDATE_SECTION: 'تحديث القسم',
      UPDATE_DETAIL: 'تحديث التفصيل',
      CREATE_MAIN_HEADING: 'إنشاء عنوان رئيسي',
      UPDATE_HEADING: 'تحديث العنوان',
      UPDATE_SUB_HEADING: 'تحديث العنوان الفرعي',
      CREATE_SUB_HEADING: 'إنشاء عنوان فرعي',
      CREATE_LABEL: 'إنشاء تسمية',
      UPDATE_LABEL: 'تحديث التسمية',
      CREATE_TOPIC: 'إنشاء موضوع',
      APPROVAL_REMARKS: 'ملاحظات الموافقة',
      CREATE_LOCATION: 'إنشاء موقع',
      EDIT_LOCATION: 'تعديل الموقع',
      CALCULATION: 'الحساب',
      CARBON_FOOTPRINT_CALCULATION: 'حساب البصمة الكربونية',
      LCA_CALCULATION: 'حساب تقييم دورة الحياة',
    },
    ERROR_TEXT: {
      INVALID_FIELD: 'حقل غير صالح',
      THIS_FIELD_IS_REQUIRED: 'هذا الحقل مطلوب',
      PLEASE_ENTER_VALID_EMAIL_ADDRESS: 'الرجاء إدخال عنوان بريد إلكتروني صالح',
    },
    TAGIFY: {
      ADMIN: 'الإدارة',
      HR: 'الموارد البشرية',
      IT: 'تكنولوجيا المعلومات',
      PRODUCTION: 'الإنتاج',
      SUPPLY_CHAIN: 'سلسلة التوريد',
      FINANCE_DEP: 'قسم المالية',
      CUSTOMER_SERVICE: 'خدمة العملاء',
      PERCENTAGE: 'النسبة المئوية',
      NUMERIC: 'رقمي',
      BOOLEAN: 'منطقي',
      CUSTOM: 'مخصص',
      NUMBER: 'رقم',
      STRING: 'نص',
      DATE: 'تاريخ',
      DAILY: 'يومي',
      WEEKLY: 'أسبوعي',
      MONTHLY: 'شهري',
      QUARTERLY: 'ربع سنوي',
      ANNUALLY: 'سنوي',
      MINIMUM: 'الحد الأدنى',
      MAXIMUM: 'الحد الأقصى',
      SUM: 'المجموع',
      AVERAGE: 'المتوسط',
    },
    PLACE_HOLDER: {
      SELECT_SCOPE: 'اختر النطاق',
      SELECT_GOAL_STATUS: 'اختر حالة الهدف',
      SELECT_LOCATION: 'اختر الموقع',
      SELECT_COUNTRY: 'اختر الدولة',
      SELECT_REGION: 'اختر المنطقة',
      SEARCH: 'بحث',
      TITLE_VALUE: 'قيمة العنوان',
      ADD_PURPOSE: 'أضف الغرض',
      SELECT_GOAL_TYPE: 'اختر نوع الهدف',
      SELECT_VALUE_TYPE: 'اختر نوع القيمة',
      SELECT_A_DATE: 'اختر تاريخًا',
      SELECT_DEPARTMENT: 'اختر القسم',
      SELECT_UNIT: 'اختر الوحدة',
      SELECT_AUDIT_REFERENCE: 'اختر مرجع التدقيق',
      SELECT_TRACKING_FREQUENCY: 'اختر تكرار التتبع',
      SELECT_CALCULATION_METHOD: 'اختر طريقة الحساب',
    },
    LABEL: {
      LOCATION: 'الموقع',
      REGION: 'المنطقة',
      COUNTRY: 'الدولة',
      FINAL_OBSERVATION: 'الملاحظة النهائية',
      SELECT_SCOPE: 'اختر النطاق',
      OBSERVATION_PERIOD: 'فترة الملاحظة',
      ENTER_OBSERVATION_PERIOD: 'أدخل فترة الملاحظة',
      GRACE_PERIOD: 'فترة السماح',
      ENTER_GRACE_PERIOD: 'أدخل فترة السماح',
      CODE: 'رمز',
      NEW_PASSWORD: 'كلمة المرور الجديدة',
      USER_NAME: 'اسم المستخدم',
      ENTER_CUSTOM_UNIT: 'أدخل وحدة مخصصة',
      CUSTOM_UNIT: 'وحدة مخصصة',
      SECTORS: 'القطاعات',
      OBJECTIVE: 'الهدف',
      METHODOLOGY: 'المنهجية',
      TOPIC_NAME: 'اسم الموضوع',
      FINAL_VALUE: 'القيمة النهائية',
      REMARKS: 'ملاحظات',
      DISPLAY_NAME: 'اسم العرض',
      PASSWORD: 'كلمة المرور',
      HOST: 'المضيف',
      PORT: 'المنفذ',
      ACTIVE: 'نشط',
      DEFAULT: 'افتراضي',
      TEMPLATE_NAME: 'اسم القالب',
      SUBJECT: 'الموضوع',
      SEVERITY: 'الخطورة',
      BLOCKER: 'العائق',
      ACTION_PLAN: 'خطة العمل',
      NOTE: 'ملاحظة',
      MANAGERS_REMARKS: 'ملاحظات المدير',
      CURRENT_VALUE: 'القيمة الحالية',
      OBJECTIVES: 'الأهداف',
      START_DATE: 'تاريخ البدء',
      END_DATE: 'تاريخ الانتهاء',
      SCOPE: 'النطاق',
      PURPOSE: 'الغرض',
      TITLE: 'العنوان',
      DESCRIPTION: 'الوصف',
      TARGET: 'الهدف',
      INITIAL_VALUE: 'القيمة الأولية',
      UNIT_VALUE: 'قيمة الوحدة',
      CALCULATION_METHOD: 'طريقة الحساب',
      TRACKING_FREQUENCY: 'تكرار التتبع',
      DEADLINE: 'الموعد النهائي',
      VALUE_TYPE: 'نوع القيمة',
      GOAL_TYPE: 'نوع الهدف',
      DEPARTMENT: 'القسم',
      UNIT: 'الوحدة',
      AUDIT_REFERENCE: 'مرجع التدقيق',
      NAME: 'الاسم',
      IS_ACTIVE: 'نشط',
      LAST_NAME: 'اسم العائلة',
      FIRST_NAME: 'الاسم الأول',
      EMAIL: 'البريد الإلكتروني',
      PHONE: 'الهاتف',
      USERNAME: 'اسم المستخدم',
      TO_EMAIL: 'إلى البريد الإلكتروني',
      VERSION: 'الإصدار',
      BODY: 'المحتوى',
      FROM_EMAIL: 'من البريد الإلكتروني',
      SENT_BY: 'أرسل بواسطة',
      SENT_TIME: 'وقت الإرسال',
      TO: 'إلى',
      VIEW_DETAILS: 'عرض التفاصيل',
      FROM: 'من',
      MESSAGE: 'الرسالة',
      HEADING: 'العنوان',
      GUIDANCE: 'التوجيه',
      DETAILINFO: 'معلومات التفصيل',
      LABEL: 'التسمية',
      CSS_CLASS: 'فئة CSS',
      IMAGE: 'الصورة',
      SUB_CATEGORY_IMAGE: 'صورة الفئة الفرعية',
      CHOOSE_CARD_COLOR: 'اختر لون البطاقة',
      CHOOSE_CARD_THEME: 'اختر نمط البطاقة',
      GRI_CODE: 'رمز GRI',
      BASELINE_VALUE: 'القيمة الأساسية',
      VERSION_NAME: 'اسم الإصدار',
      ADDRESS: 'العنوان',
      LATITUDE: 'خط العرض',
      LONGITUDE: 'خط الطول',
    },
    BUTTON: {
      VIEW_ANALYTICS: 'عرض التحليلات',
      LCA_CALC: 'حساب دورة الحياة',
      CARBON_CALC: 'حساب الكربون',
      APPLY: 'تطبيق',
      ABANDONED: 'مهجور',
      COMPLETED: 'مكتمل',
      INPROGRESS: 'قيد التنفيذ',
      CREATED: 'تم الإنشاء',
      ALL: 'الكل',
      RESET: 'إعادة تعيين',
      RESET_PASSWORD: 'إعادة تعيين كلمة المرور',
      SUMMARIZE: 'تلخيص',
      GENERATE: 'إنشاء',
      GENERATE_GRI: 'إنشاء GRI',
      LINK_TO_ESG: 'ربط بـ ESG',
      ASSIGN_TOPIC: 'تعيين موضوع',
      UPDATE: 'تحديث',
      SEND_EMAIL: 'إرسال بريد إلكتروني',
      TRACK_GOAL: 'تتبع الهدف',
      HISTORY: 'التاريخ',
      ADD: 'إضافة',
      SUBMIT: 'إرسال',
      CREATE: 'إنشاء',
      EXPORT_EXCEL: 'تصدير إلى Excel',
      ASSIGN: 'تعيين',
      CANCEL: 'إلغاء',
      ACTION: 'إجراء',
      EDIT: 'تعديل',
      DELETE: 'حذف',
      LINK_TO_TOPIC: 'ربط بالموضوع',
      OK: 'موافق',
      SAVE: 'حفظ',
      CLOSE: 'إغلاق',
    },
    ONBOARDING: {
      STOP: 'إيقاف',
      AI_WARNING_MESSAGE:
        'يرجى التحقق من محتوى الذكاء الاصطناعي بعناية قبل الإرسال',
      WRITE_WITH_AI: 'الكتابة باستخدام الذكاء الاصطناعي',
      USER_ONBOARDING: 'تعريف المستخدم',
      DEPARTMENT: 'القسم',
      EMPLOYEE: 'الموظف',
    },
    TABLE_HEADER: {
      STANDARDS: 'المعايير',
      CODE: 'رمز',
      REGIONS: 'المناطق',
      COUNTRIES: 'الدول',
      ORGANIZATION_PROFILE: 'الملف التعريفي للمنظمة',
      HEADING: 'العنوان',
      USER: 'المستخدم',
      DATE: 'التاريخ',
      CAPTURED_VALUE: 'القيمة المسجلة',
      ACTION: 'الإجراء',
      REMARKS: 'الملاحظات',
      UPDATE_HISTORY: 'تاريخ التحديث',
      DEPARTMENTS: 'الأقسام',
      PERMISSIONS: 'الصلاحيات',
      ROLES: 'الأدوار',
      USERS: 'المستخدمون',
      NAME: 'الاسم',
      DESCRIPTION: 'الوصف',
      STATUS: 'الحالة',
      ACTIONS: 'الإجراءات',
      USERNAME: 'اسم المستخدم',
      EMAIL: 'البريد الإلكتروني',
      PHONE: 'الهاتف',
      TEMPLATE_NAME: 'اسم القالب',
      SUBJECT: 'الموضوع',
      CREATE: 'إنشاء',
      SECTORS: 'القطاعات',
      GOAL_NAME: 'اسم الهدف',
      INITIAL_VALUE: 'القيمة الأولية',
      TARGET_VALUE: 'القيمة المستهدفة',
      CURRENT_VALUE: 'القيمة الحالية',
      DUE_DATE: 'تاريخ الاستحقاق',
      EMAIL_TEMPLATES: 'قوالب البريد الإلكتروني',
      CREATE_SUB_CATEGORY: 'إنشاء فئة فرعية',
      FINAL_VALUE: 'القيمة النهائية',
      BASELINE_VALUE: 'القيمة الأساسية',
      LOCATIONS: 'المواقع',
      ADDRESS: 'العنوان',
    },
    SECTORS: {
      SECTORS: 'القطاعات',
    },
    DASH: {
      COMPUTING_YOUR_QUERY: 'جاري حساب استعلامك',
      COMPLETE: 'اكتمل',
      SELECT: 'اختر',
      DECISION: 'القرار',
      CONTRIBUTORS_REMARKS: 'ملاحظات المساهمين',
      MANAGER_APPROVAL_FOR_GOAL_TRACKING: 'موافقة المدير على تتبع الهدف',
      ENTERED_VALUE: 'القيمة المدخلة',
      ACTION_PLAN: 'خطة العمل',
      BLOCKER: 'العائق',
      NOTE: 'ملاحظة',
      ESG_CATEGORIES_ABBREVATION: 'فئات ESG (البيئية والاجتماعية والحوكمة)',
      ESG_OVERVIEW: 'نظرة عامة على ESG',
      ENVIRONMENTAL: 'البيئي',
      SOCIAL: 'الاجتماعي',
      GOVERNANCE: 'الحوكمة',
      CARBON_EMISSIONS: 'انبعاثات الكربون',
      WASTE_MANAGEMENT: 'إدارة النفايات',
      ENERGY_EFFICIENCY: 'كفاءة الطاقة',
      HUMAN_RIGHTS_AND_LABOR_STANDARDS: 'حقوق الإنسان ومعايير العمل',
      COMMUNITY_RELATIONS: 'العلاقات المجتمعية',
      HEALTH_AND_SAFETY: 'الصحة والسلامة',
      SUPPLY_CHAIN_MANAGEMENT: 'إدارة سلسلة التوريد',
      HUMAN_CAPITAL_MANAGEMENT: 'إدارة رأس المال البشري',
      BIODIVERSITY_AND_CONSERVATION: 'التنوع البيولوجي والحفاظ عليه',
      SUSTAINABLE_WATER_MANAGEMENT: 'إدارة المياه المستدامة',
      ENVIRONMENTAL_POLICY_AND_REPORT: 'السياسة البيئية والتقرير',
      AUDIT_AND_INTERNAL_CONTROLS: 'التدقيق والضوابط الداخلية',
      CORPORATE_RESILIENCE_AND_ADAPTABILITY: 'المرونة والتكيف المؤسسي',
      TRANSPARENCY_AND_REPORTING: 'الشفافية وإعداد التقارير',
      STAKEHOLDER_ENGAGEMENT: 'إشراك أصحاب المصلحة',
      CORPORATE_CULTURE_AND_ETHICS: 'الثقافة المؤسسية والأخلاقيات',
      HISTORY: 'التاريخ',
      TRACK_GOAL: 'تتبع الهدف',
      STATUS: 'الحالة',
      LOGS: 'السجلات',
      USER: 'المستخدم',
      CAPTURED_VALUE: 'القيمة المسجلة',
      ACTION: 'الإجراء',
      REMARKS: 'الملاحظات',
      ROLE: 'الدور',
    },
    MENU: {
      REGIONS: 'المناطق',
      COUNTRIES: 'الدول',
      LOCATIONS: 'المواقع',
      REPORTING_TOPICS: 'مواضيع التقارير',
      PROFILE_TOPIC_MAPPING: 'ربط موضوعات الملف الشخصي',
      USER_MANAGEMENT: 'إدارة المستخدمين',
      NEW: 'جديد',
      ACTIONS: 'الإجراءات',
      CREATE_POST: 'إنشاء منشور جديد',
      PAGES: 'الصفحات',
      FEATURES: 'الميزات',
      APPS: 'التطبيقات',
      DASHBOARD: 'لوحة التحكم',
      DEPARTMENT: 'الأقسام',
      USERS: 'المستخدمون',
      ROLES: 'الأدوار',
      MASTER_DATA: 'البيانات الرئيسية',
      SECTORS: 'القطاعات',
      ESG_GOALS_AND_TARGETS: 'أهداف وغايات ESG',
      ESG_CATEGORIES: 'فئات ESG',
      MY_GOAL_TRACKING: 'تتبع أهدافي',
      GOAL_TRACKING: 'تتبع الأهداف',
      SDG_GOALS: 'أهداف التنمية المستدامة',
      ESG_AUDIT: 'تدقيق ESG',
      ESG_AUDIT_TRACKING: 'تتبع تدقيق ESG',
      ACCOUNT_MANAGEMENT: 'إدارة الحساب',
      PERMISSIONS: 'الصلاحيات',
      ONBOARDING: 'التعريف',
      COMMUNICATION: 'التواصل',
      EMAIL_TEMPLATE: 'قالب البريد الإلكتروني',
      SMTP_SETTINGS: 'إعدادات SMTP',
      PROFILE: 'الملف الشخصي',
      PROFILE_FORMS: 'نماذج الملف الشخصي',
    },
    MASTER_DATA: {
      ADD_NEW_TRACKING: 'إضافة تتبع جديد',
      GOAL_TRACKING: 'تتبع الهدف',
      VIEW_GOALS: 'عرض الأهداف',
      ADD_GOAL_FOR: 'إضافة هدف لـ',
      ESG_GOALS_AND_TARGETS: 'أهداف وغايات ESG',
      ENVIRONMENTAL: 'بيئي',
      SOCIAL: 'اجتماعي',
      GOVERNANCE: 'حوكمة',
      GOALS: 'الأهداف',
      AUDIT_RECORDS: 'سجلات التدقيق',
      CREATE_GOAL: 'إنشاء هدف',
      CREATE_AUDIT: 'إنشاء تدقيق',
      NO_POVERTY: 'القضاء على الفقر',
      ZERO_HUNGER: 'القضاء التام على الجوع',
      GOOD_HEALTH_AND_WELL_BEING: 'الصحة الجيدة والرفاه',
      GENDER_EQUALITY: 'المساواة بين الجنسين',
      QUALITY_EDUCATION: 'التعليم الجيد',
      AFFORDABLE_AND_CLEAN_ENERGY: 'طاقة نظيفة وبأسعار معقولة',
      CLEAN_WATER_AND_SANITATION: 'المياه النظيفة والنظافة الصحية',
      DECENT_WORK_AND_ECONOMIC_GROWTH: 'العمل اللائق ونمو الاقتصاد',
      CLIMATE_ACTION: 'العمل المناخي',
      LIFE_BELOW_WATER: 'الحياة تحت الماء',
      INDUSTRY_INNOVATION_AND_INFRASTRUCTURE:
        'الصناعة والابتكار والهياكل الأساسية',
      SUSTAINABLE_CITIES_AND_COMMUNITIES: 'مدن ومجتمعات محلية مستدامة',
      REDUCED_INEQUALITY: 'الحد من أوجه عدم المساواة',
      RESPONSIBLE_CONSUMPTION_AND_PRODUCTION: 'الاستهلاك والإنتاج المسؤولان',
      LIFE_ON_LAND: 'الحياة في البر',
      PEACE_JUSTICE_AND_STRONG_INSTITUTIONS: 'السلام والعدل والمؤسسات القوية',
      PARTNERSHIPS_FOR_THE_GOALS: 'عقد الشراكات لتحقيق الأهداف',
      YOU_HAVE_LENGTH_ESG_SUB_CATEGORIES: 'لديك {{length}} فئة فرعية من ESG',
      THE_ORGANIZATION_AND_ITS_REPORTING_PRACTICES:
        'المنظمة وممارسات إعداد التقارير الخاصة بها',
      ACTIVITIES_AND_WORKERS: 'الأنشطة والعاملون',
      STRATEGY_POLICIES_AND_PRACTICES: 'الاستراتيجية والسياسات والممارسات',
      STAKEHOLDER_ENGAGEMENT: 'إشراك أصحاب المصلحة',
      MATERIAL_TOPIC: 'الموضوع الجوهري',
      ORGANIZATION_DETAILS_MANAGEMENT: 'إدارة تفاصيل المنظمة',
      THE_ORGANIZATION_AND_ITS_REPORTING_PRACTICESS:
        'المنظمة وممارسات إعداد التقارير الخاصة بها',
      PROFILE_DETAILS: 'تفاصيل الملف الشخصي',
    },
    ESG_AUDIT: {
      COMPLETE_AUDIT_TRACKING: 'اكتمال تتبع التدقيق',
      UPDATE_AUDIT_TRACKING: 'تحديث تتبع التدقيق',
      GENERAL: 'عام',
      GENERATE_GRI: 'إنشاء GRI',
      AUDIT_RECORDS: 'سجلات التدقيق',
      SELECT_PARTICIPANTS: 'اختيار المشاركين',
      CREATE_AUDIT_TRACKING: 'إنشاء تتبع التدقيق',
      PARTICIPANTS: 'المشاركون',
      ADD_AUDIT_RECORS_AND_TRACK_IT: 'إضافة سجلات التدقيق وتتبعها',
      CLICK_ON_BELOW_BUTTON_TO: 'انقر على الزر أدناه لـ',
      ADD_AUDIT_RECORDS: 'إضافة سجلات التدقيق',
    },
    AUDIT_ENGAGEMENTS: {
      SELECT_REPORT_TYPE: 'اختر نوع التقرير',
      COUNTRY: 'الدولة',
      REGION: 'المنطقة',
      GENERATE_REPORT_BY: 'إنشاء تقرير بواسطة',
      LOCATION: 'الموقع',
      OBSERVATIONS: 'الملاحظات',
      ADD_OBSERVATION: 'إضافة ملاحظة',
      ATTACH_EVIDENCE: 'إرفاق دليل',
      EVIDENCE: 'الدليل',
      EVIDENCES: 'الأدلة',
      RECOMENDATIONS: 'التوصيات',
      ADD_EVIDENCE: 'إضافة دليل',
      ADD_RECOMMENDATION: 'إضافة توصية',
      SELECT_GOALS: 'اختيار الأهداف',
      IS_IMPLEMENTED: 'تم التنفيذ',
    },
    AUTH: {
      GENERAL: {
        OR: 'أو',
        SUBMIT_BUTTON: 'إرسال',
        NO_ACCOUNT: 'ليس لديك حساب؟',
        SIGNUP_BUTTON: 'التسجيل',
        FORGOT_BUTTON: 'نسيت كلمة المرور',
        BACK_BUTTON: 'رجوع',
        PRIVACY: 'الخصوصية',
        LEGAL: 'القانونية',
        CONTACT: 'اتصل بنا',
      },
      LOGIN: {
        TITLE: 'تسجيل الدخول',
        BUTTON: 'تسجيل الدخول',
      },
      FORGOT: {
        TITLE: 'نسيت كلمة المرور؟',
        DESC: 'أدخل بريدك الإلكتروني لإعادة تعيين كلمة المرور',
        SUCCESS: 'تم إعادة تعيين حسابك بنجاح.',
      },
      REGISTER: {
        TITLE: 'إنشاء حساب',
        DESC: 'أدخل تفاصيلك لإنشاء حسابك',
        SUCCESS: 'تم تسجيل حسابك بنجاح.',
      },
      INPUT: {
        EMAIL: 'البريد الإلكتروني',
        FULLNAME: 'الاسم الكامل',
        PASSWORD: 'كلمة المرور',
        CONFIRM_PASSWORD: 'تأكيد كلمة المرور',
        USERNAME: 'اسم المستخدم',
      },
      VALIDATION: {
        INVALID: '{{name}} غير صالح',
        REQUIRED: '{{name}} مطلوب',
        MIN_LENGTH: '{{name}} الحد الأدنى للطول هو {{min}}',
        AGREEMENT_REQUIRED: 'قبول الشروط والأحكام مطلوب',
        NOT_FOUND: 'العنصر المطلوب {{name}} غير موجود',
        INVALID_LOGIN: 'تفاصيل تسجيل الدخول غير صحيحة',
        REQUIRED_FIELD: 'الحقل مطلوب',
        MIN_LENGTH_FIELD: 'الحد الأدنى لطول الحقل:',
        MAX_LENGTH_FIELD: 'الحد الأقصى لطول الحقل:',
        FIELD_IS_NOT_VALID: 'الحقل غير صالح',
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'عدد السجلات المختارة: ',
        ALL: 'الكل',
        SUSPENDED: 'معلق',
        ACTIVE: 'نشط',
        FILTER: 'تصفية',
        BY_STATUS: 'حسب الحالة',
        BY_TYPE: 'حسب النوع',
        BUSINESS: 'تجاري',
        INDIVIDUAL: 'فردي',
        SEARCH: 'بحث',
        IN_ALL_FIELDS: 'في جميع المجالات',
      },
      ECOMMERCE: 'التجارة الإلكترونية',
      CUSTOMERS: {
        CUSTOMERS: 'العملاء',
        CUSTOMERS_LIST: 'قائمة العملاء',
        NEW_CUSTOMER: 'عميل جديد',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'حذف العميل',
          DESCRIPTION: 'هل أنت متأكد من أنك تريد حذف هذا العميل بشكل دائم؟',
          WAIT_DESCRIPTION: 'يتم حذف العميل...',
          MESSAGE: 'تم حذف العميل',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'حذف العملاء',
          DESCRIPTION:
            'هل أنت متأكد من أنك تريد حذف العملاء المحددين بشكل دائم؟',
          WAIT_DESCRIPTION: 'يتم حذف العملاء...',
          MESSAGE: 'تم حذف العملاء المحددين',
        },
        UPDATE_STATUS: {
          TITLE: 'تم تحديث الحالة للعملاء المحددين',
          MESSAGE: 'تم تحديث حالة العملاء المحددين بنجاح',
        },
        EDIT: {
          UPDATE_MESSAGE: 'تم تحديث العميل',
          ADD_MESSAGE: 'تم إنشاء العميل',
        },
      },
    },

    ESG_SUB_CATEGORY: {
      ADD_IMAGE: 'إضافة صورة',
      SUB_CATEGORY_IMAGE: 'صورة الفئة الفرعية',
    },
  },
};
