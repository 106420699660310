// China
export const locale = {
  lang: 'zh',
  data: {
    TRANSLATOR: {
      SELECT: '选择你的语言',
    },
    COMFIRMATION_MSG: {
      ARE_YOU_SURE_YOU_WANT_TO_DELETE: '你确定要删除吗？',
      YES_DELETE: '是的，删除！',
      NO_CANCEL: '不，取消！',
    },
    TITLE: {
      CREATE_SECTOR: '创建部门',
      EDIT_SECTOR: '编辑部门',
      CREATE_SDG_GOAL: '创建SDG目标',
      ASSIGN_GRI: '分配GRI',
      SELECT_TOPICS: '选择主题',
      EDIT_DEPARTMENT : '编辑部门',
      CREATE_DEPARTMENT : '创建部门',
      CREATE_USER : '创建用户',
      EDIT_USER : '编辑用户',
      CREATE_ROLE : '创建角色',
      EDIT_ROLE : '编辑角色',
      CREATE_PERMISSION : '创建权限',
      EDIT_PERMISSION : '编辑权限'
    },
    ERROR_TEXT: {
      THIS_FIELD_IS_REQUIRED: '此字段为必填项',
    },
    TAGIFY: {
      ADMIN: '管理员',
      HR: '人力资源',
      IT: '信息技术',
      PRODUCTION: '生产',
      SUPPLY_CHAIN: '供应链',
      FINANCE_DEP: '财务部门',
      CUSTOMER_SERVICE: '客户服务',
      PERCENTAGE: '百分比',
      NUMERIC: '数字',
      BOOLEAN: '布尔值',
      CUSTOM: '自定义',
      NUMBER: '数值',
      STRING: '字符串',
      DATE: '日期',
      DAILY: '每日',
      WEEKLY: '每周',
      MONTHLY: '每月',
      QUARTERLY: '每季',
      ANNUALLY: '每年',
      MINIMUM: '最小值',
      MAXIMUM: '最大值',
      SUM: '总和',
      AVERAGE: '平均值',
    },
    PLACE_HOLDER: {
      SEARCH: '搜索',
      TITLE_VALUE: '标题值',
      ADD_PURPOSE: '添加目的',
      SELECT_GOAL_TYPE: '选择目标类型',
      SELECT_VALUE_TYPE: '选择值类型',
      SELECT_A_DATE: '选择日期',
      SELECT_DEPARTMENT: '选择部门',
      SELECT_UNIT: '选择单位',
      SELECT_AUDIT_REFERENCE: '选择审核参考',
      SELECT_TRACKING_FREQUENCY: '选择跟踪频率',
      SELECT_CALCULATION_METHOD: '选择计算方法',
    },
    LABEL: {
      OBJECTIVES: '目标',
      START_DATE: '开始日期',
      END_DATE: '结束日期',
      SCOPE: '范围',
      PURPOSE: '目的',
      TITLE: '标题',
      DESCRIPTION: '描述',
      TARGET: '目标',
      INITIAL_VALUE: '初始值',
      UNIT_VALUE: '单位值',
      CALCULATION_METHOD: '计算方法',
      TRACKING_FREQUENCY: '跟踪频率',
      DEADLINE: '截止日期',
      VALUE_TYPE: '值类型',
      GOAL_TYPE: '目标类型',
      DEPARTMENT: '部门',
      UNIT: '单位',
      AUDIT_REFERENCE: '审核参考',
      NAME: '名称',
      IS_ACTIVE: '是否活跃',
      LAST_NAME : '姓氏',
      FIRST_NAME : '名字',
      EMAIL : '邮箱',
      PHONE : '电话',
      USERNAME : '用户名'
    },
    BUTTON: {
      SUBMIT: '提交',
      CREATE: '创建',
      EXPORT_EXCEL: '导出Excel',
      ASSIGN: '分配',
      CANCEL: '取消',
      ACTION: '操作',
      EDIT: '编辑',
      DELETE: '删除',
      LINK_TO_TOPIC: '链接到主题',
    },
    ONBOARDING: {
      USER_ONBOARDING: '用户入职',
      DEPARTMENT: '部门',
      EMPLOYEE: '员工',
    },
    TABLE_HEADER: {
      DEPARTMENTS: '部门',
      PERMISSIONS: '权限',
      ROLES: '角色',
      USERS: '用户',
      NAME: '名称',
      DESCRIPTION: '描述',
      STATUS: '状态',
      ACTIONS: '操作',
      USERNAME: '用户名',
      EMAIL: '邮箱',
      PHONE: '电话',
      TEMPLATE_NAME: '模板名称',
      SUBJECT: '主题',
      CREATE: '创建',
      SECTORS: '部门',
      GOAL_NAME: '目标名称',
      INITIAL_VALUE: '初始值',
      TARGET_VALUE: '目标值',
      CURRENT_VALUE: '当前值',
      DUE_DATE: '截止日期',
      EMAIL_TEMPLATES: '电子邮件模板',
    },
    SECTORS: {
      SECTORS: '部门',
    },
    DASH: {
      ESG_CATEGORIES_ABBREVATION: 'ESG（环境、社会和治理）类别',
      ESG_OVERVIEW: 'ESG概述',
      ENVIRONMENTAL: '环境',
      SOCIAL: '社会',
      GOVERNANCE: '治理',
      CARBON_EMISSIONS: '碳排放',
      WASTE_MANAGEMENT: '废物管理',
      ENERGY_EFFICIENCY: '能源效率',
      HUMAN_RIGHTS_AND_LABOR_STANDARDS: '人权和劳工标准',
      COMMUNITY_RELATIONS: '社区关系',
      HEALTH_AND_SAFETY: '健康与安全',
      SUPPLY_CHAIN_MANAGEMENT: '供应链管理',
      HUMAN_CAPITAL_MANAGEMENT: '人力资本管理',
      BIODIVERSITY_AND_CONSERVATION: '生物多样性与保护',
      SUSTAINABLE_WATER_MANAGEMENT: '可持续水资源管理',
      ENVIRONMENTAL_POLICY_AND_REPORT: '环境政策和报告',
      AUDIT_AND_INTERNAL_CONTROLS: '审计和内部控制',
      CORPORATE_RESILIENCE_AND_ADAPTABILITY: '企业韧性和适应能力',
      TRANSPARENCY_AND_REPORTING: '透明度和报告',
      STAKEHOLDER_ENGAGEMENT: '利益相关者参与',
      CORPORATE_CULTURE_AND_ETHICS: '企业文化和道德',
      HISTORY: '历史',
      TRACK_GOAL: '跟踪目标',
      STATUS: '状态',
    },
    MENU: {
      NEW: '新建',
      ACTIONS: '操作',
      CREATE_POST: '创建新帖子',
      PAGES: '页面',
      FEATURES: '特性',
      APPS: '应用',
      DASHBOARD: '仪表板',
      DEPARTMENT: '部门',
      USERS: '用户',
      ROLES: '角色',
      MASTER_DATA: '主数据',
      SECTORS: '部门',
      ESG_GOALS_AND_TARGETS: 'ESG目标和目标',
      ESG_CATEGORIES: 'ESG类别',
      MY_GOAL_TRACKING: '我的目标跟踪',
      SDG_GOALS: 'SDG目标',
      ESG_AUDIT: 'ESG审计',
      ESG_AUDIT_TRACKING: 'ESG审计跟踪',
      ACCOUNT_MANAGEMENT: '账户管理',
      PERMISSIONS: '权限',
      ONBOARDING: '入职',
      COMMUNICATION: '通讯',
      EMAIL_TEMPLATE: '电子邮件模板',
      SMTP_TEMPLATE: 'SMTP邮件模板',
    },
    MASTER_DATA: {
      ADD_GOAL_FOR: '为...添加目标',
      ESG_GOALS_AND_TARGETS: 'ESG目标和目标',
      ENVIRONMENTAL: '环境',
      SOCIAL: '社会',
      GOVERNANCE: '治理',
      GOALS: '目标',
      AUDIT_RECORDS: '审计记录',
      CREATE_GOAL: '创建目标',
      CREATE_AUDIT: '创建审计',
      NO_POVERTY: '无贫穷',
      ZERO_HUNGER: '零饥饿',
      GOOD_HEALTH_AND_WELL_BEING: '健康与福祉',
      GENDER_EQUALITY: '性别平等',
      QUALITY_EDUCATION: '优质教育',
      AFFORDABLE_AND_CLEAN_ENERGY: '可负担和清洁能源',
      CLEAN_WATER_AND_SANITATION: '清洁水和卫生设施',
      DECENT_WORK_AND_ECONOMIC_GROWTH: '体面工作和经济增长',
      CLIMATE_ACTION: '应对气候变化',
      LIFE_BELOW_WATER: '水下生活',
      INDUSTRY_INNOVATION_AND_INFRASTRUCTURE: '工业、创新和基础设施',
      SUSTAINABLE_CITIES_AND_COMMUNITIES: '可持续城市与社区',
      REDUCED_INEQUALITY: '减少不平等',
      RESPONSIBLE_CONSUMPTION_AND_PRODUCTION: '负责任的消费和生产',
      LIFE_ON_LAND: '陆地生命',
      PEACE_JUSTICE_AND_STRONG_INSTITUTIONS: '和平、正义和强大的机构',
      PARTNERSHIPS_FOR_THE_GOALS: '实现目标的伙伴关系',
      YOU_HAVE_LENGTH_DISCLOSURES: '你有{{length}}个披露',
    },
    ESG_AUDIT: {
      GENERAL: '一般',
      GENERATE_GRI: '生成GRI',
      AUDIT_RECORDS: '审计记录',
      SELECT_PARTICIPANTS: '选择参与者',
      CREATE_AUDIT_TRACKING: '创建审计跟踪',
      PARTICIPANTS: '参与者',
      ADD_AUDIT_RECORS_AND_TRACK_IT: '添加审计记录并跟踪',
      CLICK_ON_BELOW_BUTTON_TO: '点击下面的按钮',
      ADD_AUDIT_RECORDS: '添加审计记录'
    },    
    AUTH: {
      GENERAL: {
        OR: '要么',
        SUBMIT_BUTTON: '提交',
        NO_ACCOUNT: '没有账号？',
        SIGNUP_BUTTON: '注册',
        FORGOT_BUTTON: '忘记密码',
        BACK_BUTTON: '背部',
        PRIVACY: '隐私',
        LEGAL: '法律',
        CONTACT: '联系',
      },
      LOGIN: {
        TITLE: '创建帐号',
        BUTTON: '签到',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.',
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: '用戶名',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields',
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: '顾客',
        CUSTOMERS_LIST: '客户名单',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted',
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created',
        },
      },
    },
  },
};
