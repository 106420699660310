// Spain
export const locale = {
  lang: 'es',
  data: {
    TRANSLATOR: {
      SELECT: 'Elige tu idioma',
    },
    COMFIRMATION_MSG: {
      ARE_YOU_SURE_YOU_WANT_TO_DELETE: '¿Estás seguro de que deseas eliminar?',
      YES_DELETE: 'Sí, ¡Eliminar!',
      NO_CANCEL: 'No, Cancelar!',
    },
    TITLE: {
      CREATE_SECTOR: 'Crear Sector',
      EDIT_SECTOR: 'Editar Sector',
      CREATE_SDG_GOAL: 'Crear Objetivo de ODS',
      ASSIGN_GRI: 'Asignar GRI',
      SELECT_TOPICS: 'Seleccionar Temas',
      EDIT_DEPARTMENT: 'Editar Departamento',
      CREATE_DEPARTMENT: 'Crear Departamento',
      CREATE_USER: 'Crear Usuario',
      EDIT_USER: 'Editar Usuario',
      CREATE_ROLE: 'Crear Rol',
      EDIT_ROLE: 'Editar Rol',
      CREATE_PERMISSION: 'Crear Permiso',
      EDIT_PERMISSION: 'Editar Permiso'
    },
    ERROR_TEXT: {
      THIS_FIELD_IS_REQUIRED: 'Este campo es obligatorio',
    },
    TAGIFY: {
      ADMIN: 'Administración',
      HR: 'Recursos Humanos',
      IT: 'Tecnología de la Información',
      PRODUCTION: 'Producción',
      SUPPLY_CHAIN: 'Cadena de Suministro',
      FINANCE_DEP: 'Departamento Financiero',
      CUSTOMER_SERVICE: 'Servicio al Cliente',
      PERCENTAGE: 'Porcentaje',
      NUMERIC: 'Numérico',
      BOOLEAN: 'Booleano',
      CUSTOM: 'Personalizado',
      NUMBER: 'Número',
      STRING: 'Texto',
      DATE: 'Fecha',
      DAILY: 'Diario',
      WEEKLY: 'Semanal',
      MONTHLY: 'Mensual',
      QUARTERLY: 'Trimestral',
      ANNUALLY: 'Anualmente',
      MINIMUM: 'Mínimo',
      MAXIMUM: 'Máximo',
      SUM: 'Suma',
      AVERAGE: 'Promedio',
    },
    PLACE_HOLDER: {
      SEARCH: 'Buscar',
      TITLE_VALUE: 'Valor del Título',
      ADD_PURPOSE: 'Agregar propósito',
      SELECT_GOAL_TYPE: 'Seleccionar Tipo de Objetivo',
      SELECT_VALUE_TYPE: 'Seleccionar Tipo de Valor',
      SELECT_A_DATE: 'Seleccionar una fecha',
      SELECT_DEPARTMENT: 'Seleccionar Departamento',
      SELECT_UNIT: 'Seleccionar Unidad',
      SELECT_AUDIT_REFERENCE: 'Seleccionar Referencia de Auditoría',
      SELECT_TRACKING_FREQUENCY: 'Seleccionar Frecuencia de Seguimiento',
      SELECT_CALCULATION_METHOD: 'Seleccionar Método de Cálculo',
    },
    LABEL: {
      OBJECTIVES: 'Objetivos',
      START_DATE: 'Fecha de Inicio',
      END_DATE: 'Fecha de Fin',
      SCOPE: 'Alcance',
      PURPOSE: 'Propósito',
      TITLE: 'Título',
      DESCRIPTION: 'Descripción',
      TARGET: 'Objetivo',
      INITIAL_VALUE: 'Valor Inicial',
      UNIT_VALUE: 'Valor de la Unidad',
      CALCULATION_METHOD: 'Método de Cálculo',
      TRACKING_FREQUENCY: 'Frecuencia de Seguimiento',
      DEADLINE: 'Fecha Límite',
      VALUE_TYPE: 'Tipo de Valor',
      GOAL_TYPE: 'Tipo de Objetivo',
      DEPARTMENT: 'Departamento',
      UNIT: 'Unidad',
      AUDIT_REFERENCE: 'Referencia de Auditoría',
      NAME: 'Nombre',
      IS_ACTIVE: 'Está Activo',
      LAST_NAME: 'Apellido',
      FIRST_NAME: 'Nombre',
      EMAIL: 'Correo Electrónico',
      PHONE: 'Teléfono',
      USERNAME: 'Nombre de Usuario'
    },
    BUTTON: {
      SUBMIT: 'Enviar',
      CREATE: 'Crear',
      EXPORT_EXCEL: 'Exportar a Excel',
      ASSIGN: 'Asignar',
      CANCEL: 'Cancelar',
      ACTION: 'Acción',
      EDIT: 'Editar',
      DELETE: 'Eliminar',
      LINK_TO_TOPIC: 'Vincular al Tema',
    },
    ONBOARDING: {
      USER_ONBOARDING: 'Integración de Usuario',
      DEPARTMENT: 'Departamento',
      EMPLOYEE: 'Empleado',
    },
    TABLE_HEADER: {
      DEPARTMENTS: 'Departamentos',
      PERMISSIONS: 'Permisos',
      ROLES: 'Roles',
      USERS: 'Usuarios',
      NAME: 'Nombre',
      DESCRIPTION: 'Descripción',
      STATUS: 'Estado',
      ACTIONS: 'Acciones',
      USERNAME: 'Nombre de Usuario',
      EMAIL: 'Correo Electrónico',
      PHONE: 'Teléfono',
      TEMPLATE_NAME: 'Nombre del Modelo',
      SUBJECT: 'Asunto',
      CREATE: 'Crear',
      SECTORS: 'Sectores',
      GOAL_NAME: 'Nombre del Objetivo',
      INITIAL_VALUE: 'Valor Inicial',
      TARGET_VALUE: 'Valor Objetivo',
      CURRENT_VALUE: 'Valor Actual',
      DUE_DATE: 'Fecha de Vencimiento',
      EMAIL_TEMPLATES: 'Plantillas de Correo Electrónico',
    },
    SECTORS: {
      SECTORS: 'Sectores',
    },
    DASH: {
      ESG_CATEGORIES_ABBREVATION: 'Categorías ESG (Ambiental, Social y de Gobernanza)',
      ESG_OVERVIEW: 'Resumen ESG',
      ENVIRONMENTAL: 'Ambiental',
      SOCIAL: 'Social',
      GOVERNANCE: 'Gobernanza',
      CARBON_EMISSIONS: 'Emisiones de Carbono',
      WASTE_MANAGEMENT: 'Gestión de Residuos',
      ENERGY_EFFICIENCY: 'Eficiencia Energética',
      HUMAN_RIGHTS_AND_LABOR_STANDARDS: 'Derechos Humanos y Normas Laborales',
      COMMUNITY_RELATIONS: 'Relaciones Comunitarias',
      HEALTH_AND_SAFETY: 'Salud y Seguridad',
      SUPPLY_CHAIN_MANAGEMENT: 'Gestión de la Cadena de Suministro',
      HUMAN_CAPITAL_MANAGEMENT: 'Gestión del Capital Humano',
      BIODIVERSITY_AND_CONSERVATION: 'Biodiversidad y Conservación',
      SUSTAINABLE_WATER_MANAGEMENT: 'Gestión Sostenible del Agua',
      ENVIRONMENTAL_POLICY_AND_REPORT: 'Política y Reporte Ambiental',
      AUDIT_AND_INTERNAL_CONTROLS: 'Auditoría y Controles Internos',
      CORPORATE_RESILIENCE_AND_ADAPTABILITY: 'Resiliencia Corporativa y Adaptabilidad',
      TRANSPARENCY_AND_REPORTING: 'Transparencia y Reporte',
      STAKEHOLDER_ENGAGEMENT: 'Compromiso de las Partes Interesadas',
      CORPORATE_CULTURE_AND_ETHICS: 'Cultura Corporativa y Ética',
      HISTORY: 'Historia',
      TRACK_GOAL: 'Seguimiento del Objetivo',
      STATUS: 'Estado',
    },
    MENU: {
      NEW: 'Nuevo',
      ACTIONS: 'Acciones',
      CREATE_POST: 'Crear Nueva Publicación',
      PAGES: 'Páginas',
      FEATURES: 'Características',
      APPS: 'Aplicaciones',
      DASHBOARD: 'Tablero',
      DEPARTMENT: 'Departamentos',
      USERS: 'Usuarios',
      ROLES: 'Roles',
      MASTER_DATA: 'Datos Maestros',
      SECTORS: 'Sectores',
      ESG_GOALS_AND_TARGETS: 'Objetivos y Metas ESG',
      ESG_CATEGORIES: 'Categorías ESG',
      MY_GOAL_TRACKING: 'Seguimiento de Mis Objetivos',
      SDG_GOALS: 'Objetivos de ODS',
      ESG_AUDIT: 'Auditoría ESG',
      ESG_AUDIT_TRACKING: 'Seguimiento de Auditoría ESG',
      ACCOUNT_MANAGEMENT: 'Gestión de Cuentas',
      PERMISSIONS: 'Permisos',
      ONBOARDING: 'Integración',
      COMMUNICATION: 'Comunicación',
      EMAIL_TEMPLATE: 'Plantilla de Correo Electrónico',
      SMTP_TEMPLATE: 'Plantilla SMTP de Correo Electrónico',
    },
    MASTER_DATA: {
      ADD_GOAL_FOR: 'Agregar Objetivo para',
      ESG_GOALS_AND_TARGETS: 'Objetivos y Metas ESG',
      ENVIRONMENTAL: 'Ambiental',
      SOCIAL: 'Social',
      GOVERNANCE: 'Gobernanza',
      GOALS: 'Objetivos',
      AUDIT_RECORDS: 'Registros de Auditoría',
      CREATE_GOAL: 'Crear Objetivo',
      CREATE_AUDIT: 'Crear Auditoría',
      NO_POVERTY: 'No Pobreza',
      ZERO_HUNGER: 'Hambre Cero',
      GOOD_HEALTH_AND_WELL_BEING: 'Salud y Bienestar',
      GENDER_EQUALITY: 'Igualdad de Género',
      QUALITY_EDUCATION: 'Educación de Calidad',
      AFFORDABLE_AND_CLEAN_ENERGY: 'Energía Asequible y No Contaminante',
      CLEAN_WATER_AND_SANITATION: 'Agua Limpia y Saneamiento',
      DECENT_WORK_AND_ECONOMIC_GROWTH: 'Trabajo Decente y Crecimiento Económico',
      CLIMATE_ACTION: 'Acción por el Clima',
      LIFE_BELOW_WATER: 'Vida Submarina',
      INDUSTRY_INNOVATION_AND_INFRASTRUCTURE: 'Industria, Innovación e Infraestructura',
      SUSTAINABLE_CITIES_AND_COMMUNITIES: 'Ciudades y Comunidades Sostenibles',
      REDUCED_INEQUALITY: 'Reducción de las Desigualdades',
      RESPONSIBLE_CONSUMPTION_AND_PRODUCTION: 'Producción y Consumo Responsables',
      LIFE_ON_LAND: 'Vida de Ecosistemas Terrestres',
      PEACE_JUSTICE_AND_STRONG_INSTITUTIONS: 'Paz, Justicia e Instituciones Sólidas',
      PARTNERSHIPS_FOR_THE_GOALS: 'Alianzas para Lograr los Objetivos',
      YOU_HAVE_LENGTH_DISCLOSURES: 'Tiene {{length}} divulgaciones',
    },
    ESG_AUDIT: {
      GENERAL: 'General',
      GENERATE_GRI: 'Generar GRI',
      AUDIT_RECORDS: 'Registros de Auditoría',
      SELECT_PARTICIPANTS: 'Seleccionar Participantes',
      CREATE_AUDIT_TRACKING: 'Crear Seguimiento de Auditoría',
      PARTICIPANTS: 'Participantes',
      ADD_AUDIT_RECORS_AND_TRACK_IT: 'Agregar Registros de Auditoría y Hacer Seguimiento',
      CLICK_ON_BELOW_BUTTON_TO: 'Haz clic en los botones de abajo para',
      ADD_AUDIT_RECORDS: 'agregar registros de auditoría'
    },
    AUTH: {
      GENERAL: {
        OR: 'O',
        SUBMIT_BUTTON: 'Enviar',
        NO_ACCOUNT: 'No tienes una cuenta?',
        SIGNUP_BUTTON: 'Regístrate',
        FORGOT_BUTTON: 'Se te olvidó tu contraseña',
        BACK_BUTTON: 'Espalda',
        PRIVACY: 'Intimidad',
        LEGAL: 'Legal',
        CONTACT: 'Contacto',
      },
      LOGIN: {
        TITLE: 'Crear una cuenta',
        BUTTON: 'Registrarse',
      },
      FORGOT: {
        TITLE: 'Contraseña olvidada?',
        DESC: 'Ingrese su correo electrónico para restablecer su contraseña',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Usuario'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    }
  }
};
