// France
export const locale = {
  lang: 'fr',
  data: {
    TRANSLATOR: {
      SELECT: 'choisissez votre langue',
    },
    COMFIRMATION_MSG: {
      ARE_YOU_SURE_YOU_WANT_TO_DELETE: "Êtes-vous sûr de vouloir supprimer ?",
      YES_DELETE: "Oui, Supprimer !",
      NO_CANCEL: "Non, Annuler !",
    },
    TITLE: {
      CREATE_SECTOR: "Créer un secteur",
      EDIT_SECTOR: "Modifier le secteur",
      CREATE_SDG_GOAL: "Créer un objectif ODD",
      ASSIGN_GRI: "Assigner GRI",
      SELECT_TOPICS: "Sélectionner des sujets",
      EDIT_DEPARTMENT: "Modifier le département",
      CREATE_DEPARTMENT: "Créer un département",
      CREATE_USER: "Créer un utilisateur",
      EDIT_USER: "Modifier l'utilisateur",
      CREATE_ROLE: "Créer un rôle",
      EDIT_ROLE: "Modifier le rôle",
      CREATE_PERMISSION: "Créer une permission",
      EDIT_PERMISSION: "Modifier la permission",
    },
    ERROR_TEXT: {
      THIS_FIELD_IS_REQUIRED: "Ce champ est requis",
    },
    TAGIFY: {
      ADMIN: "Admin",
      HR: "RH",
      IT: "TI",
      PRODUCTION: "Production",
      SUPPLY_CHAIN: "Chaîne d'approvisionnement",
      FINANCE_DEP: "Département financier",
      CUSTOMER_SERVICE: "Service client",
      PERCENTAGE: "Pourcentage",
      NUMERIC: "Numérique",
      BOOLEAN: "Booléen",
      CUSTOM: "Personnalisé",
      NUMBER: "Nombre",
      STRING: "Chaîne de caractères",
      DATE: "Date",
      DAILY: "Quotidien",
      WEEKLY: "Hebdomadaire",
      MONTHLY: "Mensuel",
      QUARTERLY: "Trimestriel",
      ANNUALLY: "Annuel",
      MINIMUM: "Minimum",
      MAXIMUM: "Maximum",
      SUM: "Somme",
      AVERAGE: "Moyenne",
    },
    PLACE_HOLDER: {
      SEARCH: "Rechercher",
      TITLE_VALUE: "Valeur du titre",
      ADD_PURPOSE: "Ajouter un objectif",
      SELECT_GOAL_TYPE: "Sélectionner un type d'objectif",
      SELECT_VALUE_TYPE: "Sélectionner un type de valeur",
      SELECT_A_DATE: "Sélectionner une date",
      SELECT_DEPARTMENT: "Sélectionner un département",
      SELECT_UNIT: "Sélectionner une unité",
      SELECT_AUDIT_REFERENCE: "Sélectionner une référence d'audit",
      SELECT_TRACKING_FREQUENCY: "Sélectionner une fréquence de suivi",
      SELECT_CALCULATION_METHOD: "Sélectionner une méthode de calcul",
    },
    LABEL: {
      OBJECTIVES: "Objectifs",
      START_DATE: "Date de début",
      END_DATE: "Date de fin",
      SCOPE: "Portée",
      PURPOSE: "Objectif",
      TITLE: "Titre",
      DESCRIPTION: "Description",
      TARGET: "Cible",
      INITIAL_VALUE: "Valeur initiale",
      UNIT_VALUE: "Valeur unitaire",
      CALCULATION_METHOD: "Méthode de calcul",
      TRACKING_FREQUENCY: "Fréquence de suivi",
      DEADLINE: "Date limite",
      VALUE_TYPE: "Type de valeur",
      GOAL_TYPE: "Type d'objectif",
      DEPARTMENT: "Département",
      UNIT: "Unité",
      AUDIT_REFERENCE: "Référence d'audit",
      NAME: "Nom",
      IS_ACTIVE: "Est actif",
      LAST_NAME: "Nom de famille",
      FIRST_NAME: "Prénom",
      EMAIL: "Email",
      PHONE: "Téléphone",
      USERNAME: "Nom d'utilisateur",
    },
    BUTTON: {
      SUBMIT: "Soumettre",
      CREATE: "Créer",
      EXPORT_EXCEL: "Exporter vers Excel",
      ASSIGN: "Assigner",
      CANCEL: "Annuler",
      ACTION: "Action",
      EDIT: "Modifier",
      DELETE: "Supprimer",
      LINK_TO_TOPIC: "Lier au sujet",
    },
    ONBOARDING: {
      USER_ONBOARDING: "Intégration de l'utilisateur",
      DEPARTMENT: "Département",
      EMPLOYEE: "Employé",
    },
    TABLE_HEADER: {
      DEPARTMENTS: "Départements",
      PERMISSIONS: "Permissions",
      ROLES: "Rôles",
      USERS: "Utilisateurs",
      NAME: "Nom",
      DESCRIPTION: "Description",
      STATUS: "Statut",
      ACTIONS: "Actions",
      USERNAME: "Nom d'utilisateur",
      EMAIL: "Email",
      PHONE: "Téléphone",
      TEMPLATE_NAME: "Nom du modèle",
      SUBJECT: "Sujet",
      CREATE: "Créer",
      SECTORS: "Secteurs",
      GOAL_NAME: "Nom de l'objectif",
      INITIAL_VALUE: "Valeur initiale",
      TARGET_VALUE: "Valeur cible",
      CURRENT_VALUE: "Valeur actuelle",
      DUE_DATE: "Date d'échéance",
      EMAIL_TEMPLATES: "Modèles d'email",
    },
    SECTORS: {
      SECTORS: "Secteurs",
    },
    DASH: {
      ESG_CATEGORIES_ABBREVATION:
        "Catégories ESG (Environnementales, Sociales et de Gouvernance)",
      ESG_OVERVIEW: "Aperçu ESG",
      ENVIRONMENTAL: "Environnemental",
      SOCIAL: "Social",
      GOVERNANCE: "Gouvernance",
      CARBON_EMISSIONS: "Émissions de carbone",
      WASTE_MANAGEMENT: "Gestion des déchets",
      ENERGY_EFFICIENCY: "Efficacité énergétique",
      HUMAN_RIGHTS_AND_LABOR_STANDARDS: "Droits de l'homme et normes du travail",
      COMMUNITY_RELATIONS: "Relations communautaires",
      HEALTH_AND_SAFETY: "Santé et sécurité",
      SUPPLY_CHAIN_MANAGEMENT: "Gestion de la chaîne d'approvisionnement",
      HUMAN_CAPITAL_MANAGEMENT: "Gestion du capital humain",
      BIODIVERSITY_AND_CONSERVATION: "Biodiversité et conservation",
      SUSTAINABLE_WATER_MANAGEMENT: "Gestion durable de l'eau",
      ENVIRONMENTAL_POLICY_AND_REPORT: "Politique et rapport environnementaux",
      AUDIT_AND_INTERNAL_CONTROLS: "Audit et contrôles internes",
      CORPORATE_RESILIENCE_AND_ADAPTABILITY:
        "Résilience et adaptabilité de l'entreprise",
      TRANSPARENCY_AND_REPORTING: "Transparence et rapports",
      STAKEHOLDER_ENGAGEMENT: "Engagement des parties prenantes",
      CORPORATE_CULTURE_AND_ETHICS: "Culture d'entreprise et éthique",
      HISTORY: "Histoire",
      TRACK_GOAL: "Suivi de l'objectif",
      STATUS: "Statut",
    },
    MENU: {
      NEW: "Nouveau",
      ACTIONS: "Actions",
      CREATE_POST: "Créer un nouveau post",
      PAGES: "Pages",
      FEATURES: "Fonctionnalités",
      APPS: "Applications",
      DASHBOARD: "Tableau de bord",
      DEPARTMENT: "Départements",
      USERS: "Utilisateurs",
      ROLES: "Rôles",
      MASTER_DATA: "Données maîtres",
      SECTORS: "Secteurs",
      ESG_GOALS_AND_TARGETS: "Objectifs et cibles ESG",
      ESG_CATEGORIES: "Catégories ESG",
      MY_GOAL_TRACKING: "Suivi de mes objectifs",
      SDG_GOALS: "Objectifs ODD",
      ESG_AUDIT: "Audit ESG",
      ESG_AUDIT_TRACKING: "Suivi de l'audit ESG",
      ACCOUNT_MANAGEMENT: "Gestion du compte",
      PERMISSIONS: "Permissions",
      ONBOARDING: "Intégration",
      COMMUNICATION: "Communication",
      EMAIL_TEMPLATE: "Modèle d'email",
      SMTP_TEMPLATE: "Modèle SMTP d'email",
    },
    MASTER_DATA: {
      ADD_GOAL_FOR: "Ajouter un objectif pour",
      ESG_GOALS_AND_TARGETS: "Objectifs et cibles ESG",
      ENVIRONMENTAL: "Environnemental",
      SOCIAL: "Social",
      GOVERNANCE: "Gouvernance",
      GOALS: "Objectifs",
      AUDIT_RECORDS: "Enregistrements d'audit",
      CREATE_GOAL: "Créer un objectif",
      CREATE_AUDIT: "Créer un audit",
      NO_POVERTY: "Pas de pauvreté",
      ZERO_HUNGER: "Faim zéro",
      GOOD_HEALTH_AND_WELL_BEING: "Bonne santé et bien-être",
      GENDER_EQUALITY: "Égalité des sexes",
      QUALITY_EDUCATION: "Éducation de qualité",
      AFFORDABLE_AND_CLEAN_ENERGY: "Énergie propre et abordable",
      CLEAN_WATER_AND_SANITATION: "Eau propre et assainissement",
      DECENT_WORK_AND_ECONOMIC_GROWTH: "Travail décent et croissance économique",
      CLIMATE_ACTION: "Action pour le climat",
      LIFE_BELOW_WATER: "Vie aquatique",
      INDUSTRY_INNOVATION_AND_INFRASTRUCTURE:
        "Industrie, innovation et infrastructure",
      SUSTAINABLE_CITIES_AND_COMMUNITIES: "Villes et communautés durables",
      REDUCED_INEQUALITY: "Réduction des inégalités",
      RESPONSIBLE_CONSUMPTION_AND_PRODUCTION:
        "Consommation et production responsables",
      LIFE_ON_LAND: "Vie terrestre",
      PEACE_JUSTICE_AND_STRONG_INSTITUTIONS:
        "Paix, justice et institutions efficaces",
      PARTNERSHIPS_FOR_THE_GOALS: "Partenariats pour les objectifs",
      YOU_HAVE_LENGTH_DISCLOSURES: "Vous avez {{length}} divulgations",
    },
    ESG_AUDIT: {
      GENERAL: "Général",
      GENERATE_GRI: "Générer GRI",
      AUDIT_RECORDS: "Enregistrements d'audit",
      SELECT_PARTICIPANTS: "Sélectionner des participants",
      CREATE_AUDIT_TRACKING: "Créer un suivi d'audit",
      PARTICIPANTS: "Participants",
      ADD_AUDIT_RECORS_AND_TRACK_IT:
        "Ajouter des enregistrements d'audit et les suivre",
      CLICK_ON_BELOW_BUTTON_TO: "Cliquez sur les boutons ci-dessous pour",
      ADD_AUDIT_RECORDS: "ajouter des enregistrements d'audit",
    },    
    AUTH: {
      GENERAL: {
        OR: 'Ou',
        SUBMIT_BUTTON: 'Soumettre',
        NO_ACCOUNT: 'Ne pas avoir de compte?',
        SIGNUP_BUTTON: 'Registre',
        FORGOT_BUTTON: 'Mot de passe oublié',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Créer un compte',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.',
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Mot de passe',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: "Nom d'utilisateur",
      },
      VALIDATION: {
        INVALID: "{{name}} n'est pas valide",
        REQUIRED: '{{name}} est requis',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: "Nombre d'enregistrements sélectionnés: ",
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields',
      },
      ECOMMERCE: 'éCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Les clients',
        CUSTOMERS_LIST: 'Liste des clients',
        NEW_CUSTOMER: 'Nouveau client',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Suppression du client',
          DESCRIPTION: 'Êtes-vous sûr de supprimer définitivement ce client?',
          WAIT_DESCRIPTION: 'Le client est en train de supprimer ...',
          MESSAGE: 'Le client a été supprimé',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Supprimer les clients',
          DESCRIPTION:
            'Êtes-vous sûr de supprimer définitivement les clients sélectionnés?',
          WAIT_DESCRIPTION: 'Les clients suppriment ...',
          MESSAGE: 'Les clients sélectionnés ont été supprimés',
        },
        UPDATE_STATUS: {
          TITLE: 'Le statut a été mis à jour pour les clients sélectionnés',
          MESSAGE:
            'Le statut des clients sélectionnés a été mis à jour avec succès',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Le client a été mis à jour',
          ADD_MESSAGE: 'Le client a été créé',
        },
      },
    },
  },
};
