// Germany
export const locale = {
  lang: 'de',
  data: {
    TRANSLATOR: {
      SELECT: 'Wähle deine Sprache',
    },
    COMFIRMATION_MSG: {
      ARE_YOU_SURE_YOU_WANT_TO_DELETE: 'Sind Sie sicher, dass Sie löschen möchten?',
      YES_DELETE: 'Ja, Löschen!',
      NO_CANCEL: 'Nein, Abbrechen!',
    },
    TITLE: {
      CREATE_SECTOR: 'Sektor erstellen',
      EDIT_SECTOR: 'Sektor bearbeiten',
      CREATE_SDG_GOAL: 'SDG-Ziel erstellen',
      ASSIGN_GRI: 'GRI zuweisen',
      SELECT_TOPICS: 'Themen auswählen',
      EDIT_DEPARTMENT : 'Abteilung bearbeiten',
      CREATE_DEPARTMENT : 'Abteilung erstellen',
      CREATE_USER : 'Benutzer erstellen',
      EDIT_USER : 'Benutzer bearbeiten',
      CREATE_ROLE : 'Rolle erstellen',
      EDIT_ROLE : 'Rolle bearbeiten',
      CREATE_PERMISSION : 'Berechtigung erstellen',
      EDIT_PERMISSION : 'Berechtigung bearbeiten'
    },
    ERROR_TEXT: {
      THIS_FIELD_IS_REQUIRED: 'Dieses Feld ist erforderlich',
    },
    TAGIFY: {
      ADMIN: 'Admin',
      HR: 'HR',
      IT: 'IT',
      PRODUCTION: 'Produktion',
      SUPPLY_CHAIN: 'Lieferkette',
      FINANCE_DEP: 'Finanzabteilung',
      CUSTOMER_SERVICE: 'Kundenservice',
      PERCENTAGE: 'Prozent',
      NUMERIC: 'Numerisch',
      BOOLEAN: 'Boolesch',
      CUSTOM: 'Benutzerdefiniert',
      NUMBER: 'Nummer',
      STRING: 'Zeichenkette',
      DATE: 'Datum',
      DAILY: 'Täglich',
      WEEKLY: 'Wöchentlich',
      MONTHLY: 'Monatlich',
      QUARTERLY: 'Quartalsweise',
      ANNUALLY: 'Jährlich',
      MINIMUM: 'Minimum',
      MAXIMUM: 'Maximum',
      SUM: 'Summe',
      AVERAGE: 'Durchschnitt',
    },
    PLACE_HOLDER: {
      SEARCH: 'Suche',
      TITLE_VALUE: 'Titelwert',
      ADD_PURPOSE: 'Zweck hinzufügen',
      SELECT_GOAL_TYPE: 'Zieltyp auswählen',
      SELECT_VALUE_TYPE: 'Werttyp auswählen',
      SELECT_A_DATE: 'Datum auswählen',
      SELECT_DEPARTMENT: 'Abteilung auswählen',
      SELECT_UNIT: 'Einheit auswählen',
      SELECT_AUDIT_REFERENCE: 'Audit-Referenz auswählen',
      SELECT_TRACKING_FREQUENCY: 'Verfolgungshäufigkeit auswählen',
      SELECT_CALCULATION_METHOD: 'Berechnungsmethode auswählen',
    },
    LABEL: {
      OBJECTIVES: 'Ziele',
      START_DATE: 'Startdatum',
      END_DATE: 'Enddatum',
      SCOPE: 'Umfang',
      PURPOSE: 'Zweck',
      TITLE: 'Titel',
      DESCRIPTION: 'Beschreibung',
      TARGET: 'Ziel',
      INITIAL_VALUE: 'Anfangswert',
      UNIT_VALUE: 'Einheitswert',
      CALCULATION_METHOD: 'Berechnungsmethode',
      TRACKING_FREQUENCY: 'Verfolgungshäufigkeit',
      DEADLINE: 'Frist',
      VALUE_TYPE: 'Werttyp',
      GOAL_TYPE: 'Zieltyp',
      DEPARTMENT: 'Abteilung',
      UNIT: 'Einheit',
      AUDIT_REFERENCE: 'Audit-Referenz',
      NAME: 'Name',
      IS_ACTIVE: 'Ist aktiv',
      LAST_NAME : 'Nachname',
      FIRST_NAME : 'Vorname',
      EMAIL : 'E-Mail',
      PHONE : 'Telefon',
      USERNAME : 'Benutzername'
    },
    BUTTON: {
      SUBMIT: 'Absenden',
      CREATE: 'Erstellen',
      EXPORT_EXCEL: 'Excel exportieren',
      ASSIGN: 'Zuweisen',
      CANCEL: 'Abbrechen',
      ACTION: 'Aktion',
      EDIT: 'Bearbeiten',
      DELETE: 'Löschen',
      LINK_TO_TOPIC: 'Mit Thema verknüpfen',
    },
    ONBOARDING: {
      USER_ONBOARDING: 'Benutzer-Onboarding',
      DEPARTMENT: 'Abteilung',
      EMPLOYEE: 'Mitarbeiter',
    },
    TABLE_HEADER: {
      DEPARTMENTS: 'Abteilungen',
      PERMISSIONS: 'BERECHTIGUNGEN',
      ROLES: 'Rollen',
      USERS: 'Benutzer',
      NAME: 'Name',
      DESCRIPTION: 'Beschreibung',
      STATUS: 'Status',
      ACTIONS: 'Aktionen',
      USERNAME: 'Benutzername',
      EMAIL: 'E-Mail',
      PHONE: 'Telefon',
      TEMPLATE_NAME: 'Vorlagenname',
      SUBJECT: 'Betreff',
      CREATE: 'Erstellen',
      SECTORS: 'Sektoren',
      GOAL_NAME: 'Zielname',
      INITIAL_VALUE: 'Anfangswert',
      TARGET_VALUE: 'Zielwert',
      CURRENT_VALUE: 'Aktueller Wert',
      DUE_DATE: 'Fälligkeitsdatum',
      EMAIL_TEMPLATES: 'E-Mail-Vorlagen',
    },
    SECTORS: {
      SECTORS: 'Sektoren',
    },
    DASH: {
      ESG_CATEGORIES_ABBREVATION: 'ESG (Umwelt, Soziales und Governance) Kategorien',
      ESG_OVERVIEW: 'ESG Übersicht',
      ENVIRONMENTAL: 'Umwelt',
      SOCIAL: 'Sozial',
      GOVERNANCE: 'Governance',
      CARBON_EMISSIONS: 'Kohlenstoffemissionen',
      WASTE_MANAGEMENT: 'Abfallmanagement',
      ENERGY_EFFICIENCY: 'Energieeffizienz',
      HUMAN_RIGHTS_AND_LABOR_STANDARDS: 'Menschenrechte und Arbeitsstandards',
      COMMUNITY_RELATIONS: 'Gemeinschaftsbeziehungen',
      HEALTH_AND_SAFETY: 'Gesundheit und Sicherheit',
      SUPPLY_CHAIN_MANAGEMENT: 'Lieferkettenmanagement',
      HUMAN_CAPITAL_MANAGEMENT: 'Humankapitalmanagement',
      BIODIVERSITY_AND_CONSERVATION: 'Biodiversität und Naturschutz',
      SUSTAINABLE_WATER_MANAGEMENT: 'Nachhaltiges Wassermanagement',
      ENVIRONMENTAL_POLICY_AND_REPORT: 'Umweltpolitik und Berichterstattung',
      AUDIT_AND_INTERNAL_CONTROLS: 'Prüfung und interne Kontrollen',
      CORPORATE_RESILIENCE_AND_ADAPTABILITY: 'Unternehmensresilienz und Anpassungsfähigkeit',
      TRANSPARENCY_AND_REPORTING: 'Transparenz und Berichterstattung',
      STAKEHOLDER_ENGAGEMENT: 'Einbindung der Stakeholder',
      CORPORATE_CULTURE_AND_ETHICS: 'Unternehmenskultur und Ethik',
      HISTORY: 'Geschichte',
      TRACK_GOAL: 'Ziel verfolgen',
      STATUS: 'Status',
    },
    MENU: {
      NEW: 'Neu',
      ACTIONS: 'Aktionen',
      CREATE_POST: 'Neuen Beitrag erstellen',
      PAGES: 'Seiten',
      FEATURES: 'Funktionen',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      DEPARTMENT: 'Abteilungen',
      USERS: 'Benutzer',
      ROLES: 'Rollen',
      MASTER_DATA: 'Stammdaten',
      SECTORS: 'Sektoren',
      ESG_GOALS_AND_TARGETS: 'ESG Ziele und Ziele',
      ESG_CATEGORIES: 'ESG Kategorien',
      MY_GOAL_TRACKING: 'Meine Zielverfolgung',
      SDG_GOALS: 'SDG Ziele',
      ESG_AUDIT: 'ESG Audit',
      ESG_AUDIT_TRACKING: 'ESG Auditverfolgung',
      ACCOUNT_MANAGEMENT: 'Kontoverwaltung',
      PERMISSIONS: 'Berechtigungen',
      ONBOARDING: 'Onboarding',
      COMMUNICATION: 'Kommunikation',
      EMAIL_TEMPLATE: 'E-Mail-Vorlage',
      SMTP_TEMPLATE: 'E-Mail-SMTP-Vorlage',
    },
    MASTER_DATA: {
      ADD_GOAL_FOR: 'Ziel hinzufügen für',
      ESG_GOALS_AND_TARGETS: 'ESG Ziele und Ziele',
      ENVIRONMENTAL: 'Umwelt',
      SOCIAL: 'Sozial',
      GOVERNANCE: 'Governance',
      GOALS: 'Ziele',
      AUDIT_RECORDS: 'Prüfprotokolle',
      CREATE_GOAL: 'Ziel erstellen',
      CREATE_AUDIT: 'Audit erstellen',
      NO_POVERTY: 'Keine Armut',
      ZERO_HUNGER: 'Kein Hunger',
      GOOD_HEALTH_AND_WELL_BEING: 'Gesundheit und Wohlbefinden',
      GENDER_EQUALITY: 'Geschlechtergerechtigkeit',
      QUALITY_EDUCATION: 'Qualitätsbildung',
      AFFORDABLE_AND_CLEAN_ENERGY: 'Bezahlbare und saubere Energie',
      CLEAN_WATER_AND_SANITATION: 'Sauberes Wasser und Sanitärversorgung',
      DECENT_WORK_AND_ECONOMIC_GROWTH: 'Menschenwürdige Arbeit und Wirtschaftswachstum',
      CLIMATE_ACTION: 'Maßnahmen zum Klimawandel',
      LIFE_BELOW_WATER: 'Leben unter Wasser',
      INDUSTRY_INNOVATION_AND_INFRASTRUCTURE: 'Industrie, Innovation und Infrastruktur',
      SUSTAINABLE_CITIES_AND_COMMUNITIES: 'Nachhaltige Städte und Gemeinden',
      REDUCED_INEQUALITY: 'Ungleichheit reduzieren',
      RESPONSIBLE_CONSUMPTION_AND_PRODUCTION: 'Verantwortungsvoller Konsum und Produktion',
      LIFE_ON_LAND: 'Leben an Land',
      PEACE_JUSTICE_AND_STRONG_INSTITUTIONS: 'Frieden, Gerechtigkeit und starke Institutionen',
      PARTNERSHIPS_FOR_THE_GOALS: 'Partnerschaften für die Ziele',
      YOU_HAVE_LENGTH_DISCLOSURES: 'Sie haben {{length}} Offenlegungen',
    },
    ESG_AUDIT: {
      GENERAL: 'Allgemein',
      GENERATE_GRI: 'GRI generieren',
      AUDIT_RECORDS: 'Prüfprotokolle',
      SELECT_PARTICIPANTS: 'Teilnehmer auswählen',
      CREATE_AUDIT_TRACKING: 'Prüfungsnachverfolgung erstellen',
      PARTICIPANTS: 'Teilnehmer',
      ADD_AUDIT_RECORS_AND_TRACK_IT : 'Prüfprotokolle hinzufügen und verfolgen',
      CLICK_ON_BELOW_BUTTON_TO: 'Klicken Sie auf die unten stehenden Schaltflächen',
      ADD_AUDIT_RECORDS  : 'Prüfprotokolle hinzufügen'
    },
    AUTH: {
      GENERAL: {
        OR: 'Oder',
        SUBMIT_BUTTON: 'einreichen',
        NO_ACCOUNT: 'Hast du kein Konto?',
        SIGNUP_BUTTON: 'Anmelden',
        FORGOT_BUTTON: 'Passwort vergessen',
        BACK_BUTTON: 'Zurück',
        PRIVACY: 'Privatsphäre',
        LEGAL: 'Legal',
        CONTACT: 'Kontakt',
      },
      LOGIN: {
        TITLE: 'Create Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Nutzername'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    }
  }
};
